import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import './home.scss';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const Home = () => {

    return (
        <>
            <Header />
            <section className='main-banner'>
                <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="assets/img/slide/s1.webp" className="d-block w-100" alt="assets/img/slide/s1.jpg" />
                        </div>
                        <div className="carousel-item">
                            <img src="assets/img/slide/s2.jpg" className="d-block w-100" alt="assets/img/slide/s1.jpg" />
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </section>
            <section className='product-cards'>
                <div className='container-fluid'>
                    <div className="section-title d-flex justify-content-between">
                        <h2>NEW PRODUCTS</h2>
                        <a href=""><small>See More <i className='fa fa-right'/> </small></a>
                    </div>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={5}
                        navigation
                        // pagination={{ clickable: true }}
                        // scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <img src="assets/img/products/p1.jpeg" />
                                    <div class="overlay-btn p-1">
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                    </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">10% off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                        
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p2.jpeg" />
                                    <div class="overlay-btn p-1">
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                    </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <div class="overlay-btn p-1">
                                                <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                                <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                        </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p8.png" />
                                    <div class="overlay-btn p-1">
                                                <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                                <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                        </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <div class="overlay-btn p-1">
                                                <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i>Add to Cart</a>
                                                <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                        </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p9.jpg" />
                                        <div class="overlay-btn p-1">
                                                <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i>Add to Cart</a>
                                                <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                        </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">35% Off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            <section className='product-cards'>
                <div className='container-fluid'>
                    <div className="section-title d-flex justify-content-between">
                        <h2>FEATURED PRODUCTS</h2>
                        <a href=""><small>See More <i className='fa fa-right'/> </small></a>
                    </div>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={5}
                        navigation
                        // pagination={{ clickable: true }}
                        // scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <img src="assets/img/products/p1.jpeg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">10% off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p2.jpeg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p8.png" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">50% off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p9.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            <section className="product-cards collection">
                <div className="container-fluid">

                    <div className="section-title d-flex justify-content-between">
                        <h2>COLLECTIONS</h2>
                        <a href=""><small>See More <i className='fa fa-right'/> </small></a>
                    </div>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={5}
                        navigation
                        // pagination={{ clickable: true }}
                        // scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                            <img src="assets/img/products/p1.jpeg" />
                            <p className="card-text text-center">Collection 1</p>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                            <img src="assets/img/products/p6.jpeg" />
                            <p className="card-text text-center">Collection 1</p>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                            <img src="assets/img/products/p2.jpeg" />
                            <p className="card-text text-center">Collection 1</p>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                            <img src="assets/img/products/p3.jpg" />
                            <p className="card-text text-center">Collection 1</p>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                            <img src="assets/img/products/p4.jpg" />
                            <p className="card-text text-center">Collection 1</p>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                            <img src="assets/img/products/p5.jpg" />
                            <p className="card-text text-center">Collection 1</p>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                            <img src="assets/img/products/p6.jpeg" />
                            <p className="card-text text-center">Collection 1</p>
                            </>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <section className='product-cards'>
                <div className="container-fluid">
                    <div className="section-title d-flex justify-content-between">
                        <h2>YOUR BROWSING HISTORY</h2>
                        <a href=""><small>See More <i className='fa fa-right'/> </small></a>
                    </div>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={5}
                        navigation
                        // pagination={{ clickable: true }}
                        // scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <img src="assets/img/products/p1.jpeg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p2.jpeg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p8.png" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p8.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <Footer />
        </>
    );
}
export default Home;