import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
// import { Button } from 'react-bootstrap';
const Checkout = () => {
    return (
        <>
            <Header />

            <main id="main">
    {/* ======= Breadcrumbs Section ======= */}
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Checkout</h2>
          <ol>
            <li>
              <a href="index.html">Home</a>
            </li>
            <li>Checkout</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Breadcrumbs Section */}
    <section className="inner-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <h5>Billing Details</h5>

            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Address Detail</Accordion.Header>
                    <Accordion.Body>
                      <p>Billing Address</p>
                      <Form>
                      {['checkbox'].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                          <Form.Check
                            inline
                            label="Unicode Systems, Tedhi Puliya, Lucknow, 226022, Uttar Pradesh"
                            name="group1"
                            type={type}
                            id={`inline-${type}-1`}
                          />
                        </div>
                      ))}
                    </Form>
                      <p>Add New Address</p>
                    <form
              action="forms/appointment.php"
              method="post"
              role="form"
              className="php-email-form"
            >
              <div className="row">
                <div className="col-md-4 form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div className="validate" />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                  />
                  <div className="validate" />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    id="phone"
                    placeholder="Your Phone"
                  />
                  <div className="validate" />
                </div>
                <div className="col-md-12 form-group mt-3 ">
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    id="phone"
                    placeholder="Address"
                  />
                  <div className="validate" />
                </div>
                <div className="col-md-4 form-group mt-3 ">
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    id="phone"
                    placeholder="City"
                  />
                  <div className="validate" />
                </div>
                <div className="col-md-4 form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    id="phone"
                    placeholder="State"
                  />
                  <div className="validate" />
                </div>
                <div className="col-md-4 form-group mt-3 ">
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    id="phone"
                    placeholder="Pincode"
                  />
                  <div className="validate" />
                </div>
                <div className="col-md-12 form-group mt-3 right">
                  <button className='btn btn-sm btn-primary'>Submit</button>
                </div>
              </div>
              {/* <div class="form-group mt-3">
          <textarea class="form-control" name="message" rows="3" placeholder="Street Address"></textarea>
          <div class="validate"></div>
        </div> */}
            </form>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Payment Detail</Accordion.Header>
                    <Accordion.Body>
                    <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  onclick="show2();"
                  defaultChecked=""
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Credit Card
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  onclick="show2();"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Debit Card
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  onclick="show1();"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Cash On Delivery
                </label>
              </div>
              <p />
              <form
                action="forms/"
                method="post"
                role="form"
                className="php-email-form"
                id="div1"
              >
                <div className="row">
                  <div className="col-md-5 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-7 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Credit card number"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      id="phone"
                      placeholder="Expiration"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group mt-3 ">
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      id="phone"
                      placeholder="CVV"
                    />
                    <div className="validate" />
                  </div>
                </div>
              </form>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Order Summary</Accordion.Header>
                    <Accordion.Body>
                    <table className="table">
                <thead>
                  <tr></tr>
                </thead>
                <tbody></tbody>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Sub Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Package 2 × 3</td>
                    <td>₹1,350.00</td>
                  </tr>
                  <tr>
                    <td> Subtotal</td>
                    <td>₹1,350.00</td>
                  </tr>
                  <tr>
                    <td> Shipping </td>
                    <td>Free shipping</td>
                  </tr>
                  <tr>
                    <th>Total</th>
                    <th>₹1,350.00</th>
                  </tr>
                </tbody>
              </table>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-primary">Order Detail</h5>
              </div>
              <table className="table">
                <thead>
                  <tr></tr>
                </thead>
                <tbody></tbody>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Sub Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Package 2 × 3</td>
                    <td>₹1,350.00</td>
                  </tr>
                  <tr>
                    <td> Subtotal</td>
                    <td>₹1,350.00</td>
                  </tr>
                  <tr>
                    <td> Shipping </td>
                    <td>Free shipping</td>
                  </tr>
                  <tr>
                    <th>Total</th>
                    <th>₹1,350.00</th>
                  </tr>
                </tbody>
              </table>
              <div className="card-body">
                <p>
                  Your personal data will be used to process your order, support
                  your experience throughout this website, and for other
                  purposes described in our{" "}
                  <span className="text-primary"> privacy policy.</span>
                </p>
                <a href="" className="card-link btn btn-md btn-primary">
                  Place Order
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  {/* End #main */}
<Footer />
        </>
    )
}
export default Checkout;