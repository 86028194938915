import React from 'react';
function PropButton(props) {
    return ( 
        <>
        <button href="/cart" className='btn btn-sm btn-primary w-100'>
            {props.btnname}
        </button>
        </>
    )
}
export default PropButton;
