
import React from 'react';
const Footer = () => {
    return (
        <>
          <footer id="footer">
            <div className="footer-top">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6 footer-links">
                    <h4>Quick Links</h4>
                    <ul>
                      <li><i className="bx bx-chevron-right" /> <a href="#">Privacy and Policy</a></li>
                      <li><i className="bx bx-chevron-right" /> <a href="#">Search Terms</a></li>
                      <li><i className="bx bx-chevron-right" /> <a href="#">Order and Returns</a></li>
                      <li><i className="bx bx-chevron-right" /> <a href="#">Advance Search</a></li>
                      <li><i className="bx bx-chevron-right" /> <a href="#">Contact Us</a></li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-6 footer-links">
                    <h4>My Account</h4>
                    <ul>
                      <li><i className="bx bx-chevron-right" /> <a href="#">My Account</a></li>
                      <li><i className="bx bx-chevron-right" /> <a href="#">Login</a></li>
                      <li><i className="bx bx-chevron-right" /> <a href="#">My Cart</a></li>
                      <li><i className="bx bx-chevron-right" /> <a href="#">My Compare</a></li>
                      <li><i className="bx bx-chevron-right" /> <a href="#">My Wishlist</a></li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-6 footer-links">
                    <h4>Payment &amp; Shipping</h4>
                    <ul>
                      <li><i className="bx bx-chevron-right" /> <a href="#">Terms of use</a></li>
                      <li><i className="bx bx-chevron-right" /> <a href="#">Payment Methods</a></li>
                      <li><i className="bx bx-chevron-right" /> <a href="#">Shipping Guide</a></li>
                      <li><i className="bx bx-chevron-right" /> <a href="#">Order Tracking</a></li>
                      <li><i className="bx bx-chevron-right" /> <a href="#">Return &amp; Exchange</a></li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-6 footer-contact">
                    <h4>Quick Contact</h4>
                    <p>265 Lonron Street, NEW york, USA</p>
                    <p>Phone : +1 23565689</p>
                    <p>Email: mail@mail.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container d-md-flex py-4">
              <div className="me-md-auto text-center text-md-start">
                <div className="copyright">
                  © Copyright <strong>Unicode</strong>. All Rights Reserved
                </div>
                <div className="credits">
                </div>
              </div>
              <div className="social-links text-center text-md-right pt-3 pt-md-0">
                <a href="#" className="twitter"><i className="bx bxl-twitter" /></a>
                <a href="#" className="facebook"><i className="bx bxl-facebook" /></a>
                <a href="#" className="instagram"><i className="bx bxl-instagram" /></a>
                <a href="#" className="google-plus"><i className="bx bxl-skype" /></a>
                <a href="#" className="linkedin"><i className="bx bxl-linkedin" /></a>
              </div>
            </div>
          </footer>{/* End Footer */}
          
        </>
      );
}
export default Footer;