import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper';
import { Navigation, Scrollbar, A11y } from 'swiper';
import Card from 'react-bootstrap/Card';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Cart = () => {
  return (
    <>
      <Header />

      <main id="main" style={{ backgroundColor: "#eee" }}>
        {/* ======= Breadcrumbs Section ======= */}
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Cart</h2>
              <ol>
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>Cart</li>
              </ol>
            </div>
          </div>
        </section>
        {/* End Breadcrumbs Section */}
        <section className="inner-page">
          <div className="container-fluid">
            <div className='row'>
              <div className='col-md-9'>
                <div className="ibox">
                  <div className="ibox-title">
                    <span className="pull-right">(<strong>2</strong>) items</span>
                    <h5>Items in your cart</h5>
                  </div>
                  <div className="ibox-content">
                    <div className="table-responsive">
                      <table className="table shoping-cart-table">
                        <tbody>
                          <tr>
                            <td>
                              <div className="cart-product-imitation">
                                <img src="assets/img/products/p3.jpg" height="100%" />
                              </div>
                            </td>
                            <td className="desc">
                              <h5>
                                <a href="#">
                                  Soulflower Lavender Essential Oil | Skin, Acne, Hair Fall, Relaxing Sleep, Aromatherapy & Body | Lavandula Angustifolia | Pure, Organi…
                                </a>
                              </h5>
                              <p className="small">
                                Lavender Essential Oil helps in moisturizing skin and cleanses the skin by unclogging pores and clearing acne, getting a soft, nourished and moisturized skin.
                              </p>
                              <dl className="small m-b-none">
                                <dt>Description lists</dt>
                                <dd>A description list is perfect for defining terms.</dd>
                              </dl>
                              <div className="m-t-sm cart-text-muted">
                                <a href="#" className="text-muted"><i className="fa fa-gift" /> Add gift package</a>
                                <a href="#" className="text-muted"> | </a>

                                <a href="#" className="text-muted"><i className="fa fa-trash" /> Remove item</a>
                                <a href="#" className="text-muted"> | </a>

                                <a href="#" className="text-muted"> Save for later</a>
                              </div>
                            </td>
                            <td>
                              <h4>
                                ₹ 180
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="ibox-content">
                    <div className="table-responsive">
                      <table className="table shoping-cart-table">
                        <tbody>
                          <tr>
                            <td>
                              <div className="cart-product-imitation">
                                <img src="assets/img/products/p2.jpeg" height="100%" />
                              </div>
                            </td>
                            <td className="desc">
                              <h5>
                                <a href="#">
                                  Soulflower Lavender Essential Oil | Skin, Acne, Hair Fall, Relaxing Sleep, Aromatherapy & Body | Lavandula Angustifolia | Pure, Organi…
                                </a>
                              </h5>
                              <p className="small">
                                Lavender Essential Oil helps in moisturizing skin and cleanses the skin by unclogging pores and clearing acne, getting a soft, nourished and moisturized skin.
                              </p>
                              <dl className="small m-b-none">
                                <dt>Scent Name : Lavender</dt>
                                <dt>Size : 10 ml (Pack of 1)</dt>
                              </dl>
                              <div className="m-t-sm cart-text-muted">
                                <a href="#" className="text-muted"><i className="fa fa-gift" /> Add gift package</a>
                                <a href="#" className="text-muted"> | </a>

                                <a href="#" className="text-muted"><i className="fa fa-trash" /> Remove item</a>
                                <a href="#" className="text-muted"> | </a>

                                <a href="#" className="text-muted"> Save for later</a>
                              </div>
                            </td>
                            <td>
                              <h4>
                                ₹ 400
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="ibox-content pb-5">
                    <a href='/' className=" pull-right">
                    <span className="text-muted small">
                        Subtotal (2 items):   <b>₹ 54,139.00</b>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                        
                <div className="row ibox">
                  <div className='col-md-12'>
                    <div className="ibox-title">
                      <h5>Cart Summary</h5>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className="ibox-content">
                      <small><i className='fa fa-check-circle'/> Your order is eligible for FREE Delivery. Select this option at checkout. </small>
                      <hr />
                      <span className="text-muted small">
                        Subtotal (2 items):   <b>₹ 54,139.00</b>
                      </span>
                          <button href="#" className="btn btn-primary btn-sm mt-2 w-100"><i className="fa fa-shopping-cart" /> Proceed to Checkout</button>
                    </div>
                    </div>
                </div>
                <div className="blog">
                            <div className="sidebar">
                            <h3 className="sidebar-title">Products related to items in your cart</h3>
                                <div className="sidebar-item recent-posts">
                                    <div className="post-item clearfix">
                                        <img src="assets/img/products/p2.jpeg" alt="" />
                                        <h4><a href="#">Fournier Anne Stoke</a></h4>
                                        <time dateTime="2020-01-01"><div className="ratings">
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star" />
                                        </div></time>
                                        <div className="product-price-list">
                                            <small area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <small className="a-price-whole" style={{fontSize:'16px'}}>1400</small>
                                                <del>₹200</del>
                                                <small className="price-off">(37% off)</small>
                                            </small>
                                        </div>
                                    </div>
                                    <div className="post-item clearfix">
                                        <img src="assets/img/products/p1.jpeg" alt="" />
                                        <h4><a href="#">Legends Tarot Cards</a></h4>
                                        <time dateTime="2020-01-01"><div className="ratings">
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star" />
                                        </div></time>
                                        <div className="product-price-list">
                                            <small area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <small className="a-price-whole" style={{fontSize:'16px'}}>1400</small>
                                                <del>₹200</del>
                                                <small className="price-off">(37% off)</small>
                                            </small>
                                        </div>
                                    </div>
                                    <div className="post-item clearfix">
                                        <img src="assets/img/products/p3.jpg" alt="" />
                                        <h4><a href="#">Id quia et et ut maxime similique occaecati ut</a></h4>
                                        <time dateTime="2020-01-01"><div className="ratings">
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star" />
                                        </div></time>
                                        <div className="product-price-list">
                                            <small area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <small className="a-price-whole" style={{fontSize:'16px'}}>1400</small>
                                                <del>₹200</del>
                                                <small className="price-off">(37% off)</small>
                                            </small>
                                        </div>
                                    </div>
                                    <div className="post-item clearfix">
                                        <img src="assets/img/products/p4.jpg" alt="" />
                                        <h4><a href="#">Legends Tarot Cards</a></h4>
                                        <time dateTime="2020-01-01">
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                        </time>
                                        <div className="product-price-list">
                                            <small area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <small className="a-price-whole" style={{fontSize:'16px'}}>1400</small>
                                                <del>₹200</del>
                                                <small className="price-off">(37% off)</small>
                                            </small>
                                        </div>
                                    </div>
                                </div>{/* End sidebar products*/}
                            </div>
                        </div>
                
              </div>
            </div>

            {/* <div className="shopping-cart">
              <div className="column-labels">
                <label className="product-image">Image</label>
                <label className="product-details">Product</label>
                <label className="product-price">Price</label>
                <label className="product-quantity">Quantity</label>
                <label className="product-removal">Remove</label>
                <label className="product-line-price">Total</label>
              </div>
              <div className="product">
                <div className="product-image">
                  <img src="assets/img/products/p3.jpg" />
                </div>
                <div className="product-details">
                  <div className="product-title">
                    Fournier Anne Stokes Legends
                  </div>
                </div>
                <div className="product-price">12.99</div>
                <div className="product-quantity">
                  <input type="number" defaultValue={2} min={1} />
                </div>
                <div className="product-removal">
                  <button className="remove-product"><i className='fa fa-trash-o' /></button>
                </div>
                <div className="product-line-price">25.98</div>
              </div>
              <div className="product">
                <div className="product-image">
                  <img src="assets/img/products/p4.jpg" />
                </div>
                <div className="product-details">
                  <div className="product-title">Fournier Anne Stokes Legends </div>
                </div>
                <div className="product-price">45.99</div>
                <div className="product-quantity">
                  <input type="number" defaultValue={1} min={1} />
                </div>
                <div className="product-removal">
                  <button className="remove-product"><i className='fa fa-trash-o' /></button>
                </div>
                <div className="product-line-price">45.99</div>
              </div>
              <div className="totals">
                <div className="totals-item">
                  <label>Subtotal</label>
                  <div className="totals-value" id="cart-subtotal">
                    71.97
                  </div>
                </div>
                <div className="totals-item">
                  <label>Tax (5%)</label>
                  <div className="totals-value" id="cart-tax">
                    3.60
                  </div>
                </div>
                <div className="totals-item">
                  <label>Shipping</label>
                  <div className="totals-value" id="cart-shipping">
                    15.00
                  </div>
                </div>
                <div className="totals-item totals-item-total">
                  <label>Grand Total</label>
                  <div className="totals-value" id="cart-total">
                    90.57
                  </div>
                </div>
              </div>
              <a href="checkout.html">
                <button className="checkout btn-sm btn btn-primary">
                  Proceed to Checkout
                </button>
              </a>
            </div> */}
          </div>
        </section>
        <section className='product-cards'>
                <div className='container-fluid'>
                    <div className="section-title d-flex justify-content-between">
                        <h2>Recommended based on your shopping trends</h2>
                        <a href=""><small>See More <i className='fa fa-right'/> </small></a>
                    </div>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={5}
                        navigation
                        // pagination={{ clickable: true }}
                        // scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <img src="assets/img/products/p1.jpeg" />
                                    <div class="overlay-btn p-1">
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                    </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">10% off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p2.jpeg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p8.png" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">50% off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p8.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
        <section className='product-cards'>
                <div className='container-fluid'>
                    <div className="section-title d-flex justify-content-between">
                        <h2>Customers who bought items in your cart also bought</h2>
                        <a href=""><small>See More <i className='fa fa-right'/> </small></a>
                    </div>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={5}
                        navigation
                        // pagination={{ clickable: true }}
                        // scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <img src="assets/img/products/p1.jpeg" />
                                    <div class="overlay-btn p-1">
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                    </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">10% off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p2.jpeg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p8.png" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">50% off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p8.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
      </main>
      <Footer />
    </>
  )
}
export default Cart;