import logo from './logo.svg';
import './App.css';
// import './style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter,
  Routes,
  Route,} from "react-router-dom";

  import Home from './home/Home';
  import Productlist from './product-listing/Listproduct';
  import Cart from './cart/Cart';
  import Checkout from './checkout/Checkout';
  import ProductDetail from './product-detail/Productdetail';

function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/list-product" element={<Productlist />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/checkout" element={<Checkout />} />
          <Route exact path="/product-detail" element={<ProductDetail />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
