
import React from 'react';
const Header = () => {
return (
    <div>

<div id="topbar" className="d-flex align-items-center fixed-top">
          <div className="container d-flex justify-content-between">
            <div className="contact-info d-flex align-items-center">
              <a href="/" className="logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" className="img-fluid" /></a>
            </div>
            <div className="contact-info d-flex align-items-center">
                <div className="search-bar">
                <div className="search d-flex">
                  <div className="btn-group">
                    
                    <input type="text" className="form-control" placeholder="Search Here ..." />
                    <span className="search-btn"><i className="bi bi-search" /></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-flex social-links align-items-center">
              {/*<a href="#" class="twitter text-primary"><i class="bi bi-phone"></i> +1-888-689-9652</a>
        <a href="#" class="twitter text-primary"><i class="bi bi-envelope"></i> info@unicodesystems.in</a>*/}
              <a href="#" className="twitter text-primary">Hello Unicode,</a>
              <a href="#" className="twitter text-primary">My Account</a>
              <a href="/cart" className="twitter text-primary"><i className='fa fa-shopping-cart'/> cart</a>
            </div>
          </div>
        </div>
        {/* ======= Header ======= */}
        <header id="header" className="fixed-top ">
          <div className="container d-flex align-items-center justify-content-lg-center">
          <div className="contact-info d-flex align-items-center me-auto">
          <i class="fa fa-map-marker me-2 text-white" aria-hidden="true"/> <a href="#" className='me-2 text-white'>Select your address</a>
          </div>
            {/* Uncomment below if you prefer to use an image logo */}
            {/* <a href="/" className="d-none logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" className="img-fluid" /></a> */}
            <nav id="navbar" className="navbar order-last order-lg-0">
              <ul>
                <li><a className="nav-link" href="#">All</a></li>
                <li><a className="nav-link" href="#">Sell</a></li>
                <li className="dropdown"><a href="#"><span>Category 1</span> <i className="bi bi-chevron-down" /></a>
                  <ul>
                    <li className="dropdown"><a href="#"><span>cat 1</span> </a></li>
                    <li className="dropdown"><a href="#"><span>cat 2</span> </a></li>
                    <li className="dropdown"><a href="#"><span>cat 3</span> </a></li>
                  </ul>
                </li>
                <li><a className="nav-link scrollto" href="#"></a></li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle" />
            </nav>{/* .navbar */}
          </div>
        </header>{/* End Header */}
    </div>
  );
}
export default Header;