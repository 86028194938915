import React, {useState} from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import './detail.scss';
// import Swiper core and required modules
import { Navigation, Scrollbar, A11y } from 'swiper';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import PropButton from '../component/button/Button';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Pagination from 'react-bootstrap/Pagination';
let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>,
  );
}

const ProductDetail = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    return (
        <>
            <Header />
            <section className="product-cards" style={{ paddingTop: '140px' }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 p-5">
                        <Swiper modules={[Thumbs]} thumbs={{ swiper: thumbsSwiper }}>
                            
                        </Swiper>
                        
                        
                        </div>
                        <div className="col-md-5">
                            
                            <div className="row">
                                <div className="col col-sm-12 p-5">
                                <div class="product-dtl">
                                    <div class="product-info">
                                        <h4>Deve Herbes Genius Brain Power Booster Aromatherapy Essential Oil Blend</h4>
                                        <div className="ratings">
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star" />
                                            <small className='me-2'> | 245 ratings</small>
                                        </div>
                                        
                                        <span className="product-deal text-danger">Deal of the Day</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">200</span>
                                                <del>₹400</del>
                                                <span className="price-off ms-2">(37% off)</span>
                                            </span>
                                            <br/>
                                            <small>Inclusive of all taxes</small>
                                        </div>
                                        <p className='mt-2'><b>Size : </b>10 ml (Pack of 1)</p>
                                        <hr/>
                                        <table className='table'>
                                            <tbody className='about-product'>
                                                <tr>
                                                    <td>Brand</td>
                                                    <td>Soulflower</td>
                                                </tr>
                                                <tr>
                                                    <td>Scent</td>
                                                    <td>Lavender</td>
                                                </tr>
                                                <tr>
                                                    <td>Special Feature</td>
                                                    <td>Travel Size, Aromatherapy, Antibacterial</td>
                                                </tr>
                                                <tr>
                                                    <td>Product Benefits</td>
                                                    <td>Relaxation, Stress Relief</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <hr/>
                                    </div>
                                    <div class="row">
                                        <p><b>About this item :</b></p>
                                        <p>22 Saalo ka Bharosa" for HEALTHY HAIR: Soulflower Lavender Essential Oil Therapeutic Grade for thicker, healthier hair. Lavender oil helps with scalp problems, dandruff, and hair fall and promotes hair growth. It conditions the hair and softens strands for shiny, soft, and smooth tresses.</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 detailin-product shadow p-2'>
                            <div className='card p-3'>
                                <div className="product-price-list">
                                    <span area-hidden="true">
                                        <span className="a-price-symbol">₹</span>
                                        <span className="a-price-whole">200</span>
                                        <del>₹400</del>
                                        <span className="price-off ms-2">(37% off)</span>
                                    </span>
                                    <br/>
                                    <small><span className='text-primary'>Free Delivery </span><b>Thursday, 20 April </b>on first order</small>
                                    <br/><small>or fastest Delivery <b>Tomorrow April 16 </b>on first order</small>
                                    <p className='text-primary'>In Stock</p>
                                    <small>Sold by <b> RK World Infocom Pvt Ltd and Fulfilled by Amazon.</b></small>
                                    <hr/>
                                    <form>
                                        {/* <label for="qty">Quantity: </label> */}
                                        <form action="/action_page.php">
                                        <label for="quantity" className='me-2'>Quantity : </label>
                                        <input type="number" value="1" id="quantity" name="quantity" min="1" max="5"/>
                                        </form>
                                        <br/>
                                    </form>
                                </div>
                                <div className='row mt-2 align-item-center'>
                                    <div className='col'>
                                        <PropButton btnname='Add To Cart'/>
                                    </div>
                                </div>
                                <div className='row mt-2 align-item-center'>
                                    <div className='col'>
                                        <PropButton btnname='Buy Now'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr/>
            <section className='product-cards'>
                <div className='container-fluid'>
                    <div className="section-title d-flex justify-content-between">
                        <h2>FEATURED PRODUCTS</h2>
                        <a href=""><small>See More <i className='fa fa-right'/> </small></a>
                    </div>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={5}
                        navigation
                        // pagination={{ clickable: true }}
                        // scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <img src="assets/img/products/p1.jpeg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">10% off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p2.jpeg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p8.png" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">50% off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p8.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            <hr/>
          <section>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-4'>
                        <h5 class="heading">User Rating</h5>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <p>4.1 average based on 254 reviews.</p>
                        <hr/>

                        <div class="row">
                        <div class="side">
                            <div>5 star</div>
                        </div>
                        <div class="middle">
                            <div class="bar-container">
                            <div class="bar-5"></div>
                            </div>
                        </div>
                        <div class="side right">
                            <div>150</div>
                        </div>
                        <div class="side">
                            <div>4 star</div>
                        </div>
                        <div class="middle">
                            <div class="bar-container">
                            <div class="bar-4"></div>
                            </div>
                        </div>
                        <div class="side right">
                            <div>63</div>
                        </div>
                        <div class="side">
                            <div>3 star</div>
                        </div>
                        <div class="middle">
                            <div class="bar-container">
                            <div class="bar-3"></div>
                            </div>
                        </div>
                        <div class="side right">
                            <div>15</div>
                        </div>
                        <div class="side">
                            <div>2 star</div>
                        </div>
                        <div class="middle">
                            <div class="bar-container">
                            <div class="bar-2"></div>
                            </div>
                        </div>
                        <div class="side right">
                            <div>6</div>
                        </div>
                        <div class="side">
                            <div>1 star</div>
                        </div>
                        <div class="middle">
                            <div class="bar-container">
                            <div class="bar-1"></div>
                            </div>
                        </div>
                        <div class="side right">
                            <div>20</div>
                        </div>
                        </div>

                    </div>
                    <div className='col-md-8'>
                        
                        <div className="dreview p-5">
                        <h5>Top Reviews</h5>
                        <br/>
                            <div className="row" style={{borderBottom: '1px solid rgb(201, 201, 201)', marginBottom: '12px'}}>
                                <div className="col-md-6">
                                    <div className="d-flex  align-items-center">
                                        <h5 className="rate-review">&nbsp;</h5>
                                        <div className="ratings">
                                            <i className="fa fa-star checked rating-color" />
                                            <i className="fa fa-star checked rating-color" />
                                            <i className="fa fa-star checked rating-color" />
                                            <i className="fa fa-star checked rating-color" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <p className="rate-review"> &nbsp;  (13)</p>
                                    </div>
                                    <p><small>Date : 26 January 2019</small></p>
                                </div>
                                <div className="col-md-6 text-end"><p className="text-danger"> - Manju</p></div>
                                <div className="col-md-12"><p className="text-justify">I lost so much hair from postpartum hair loss that the front of my hairline was so thin and my temples were almost bald. I used this once a week and massaged into my scalp, left on for 1 - 2 hours then washed my hair as normal. </p></div>
                            </div>
                            <div className="row" style={{borderBottom: '1px solid rgb(201, 201, 201)', marginBottom: '12px'}}>
                                <div className="col-md-6">
                                    <div className="d-flex  align-items-center">
                                        <h5 className="rate-review">&nbsp;</h5>
                                        <div className="ratings">
                                            <i className="fa fa-star checked rating-color" />
                                            <i className="fa fa-star checked rating-color" />
                                            <i className="fa fa-star checked rating-color" />
                                            <i className="fa fa-star checked rating-color" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <p className="rate-review"> &nbsp;  (13)</p>
                                    </div>
                                    <p><small>Date : 26 January 2019</small></p>
                                </div>
                                <div className="col-md-6 text-end"><p className="text-danger"> - Ankit</p></div>
                                <div className="col-md-12"><p className="text-justify">I lost so much hair from postpartum hair loss that the front of my hairline was so thin and my temples were almost bald. I used this once a week and massaged into my scalp, left on for 1 - 2 hours then washed my hair as normal. </p></div>
                            </div>
                            <div className="row" style={{borderBottom: '1px solid rgb(201, 201, 201)', marginBottom: '12px'}}>
                                <div className="col-md-6">
                                    <div className="d-flex  align-items-center">
                                        <h5 className="rate-review">&nbsp;</h5>
                                        <div className="ratings">
                                            <i className="fa fa-star checked rating-color" />
                                            <i className="fa fa-star checked rating-color" />
                                            <i className="fa fa-star checked rating-color" />
                                            <i className="fa fa-star checked rating-color" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <p className="rate-review"> &nbsp;  (13)</p>
                                    </div>
                                    <p><small>Date : 26 January 2019</small></p>
                                </div>
                                <div className="col-md-6 text-end"><p className="text-danger"> - Disha</p></div>
                                <div className="col-md-12"><p className="text-justify">I lost so much hair from postpartum hair loss that the front of my hairline was so thin and my temples were almost bald. I used this once a week and massaged into my scalp, left on for 1 - 2 hours then washed my hair as normal. </p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </section>
            <Footer />
        </>
    )
}
export default ProductDetail;