import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import './list.scss';
// import Swiper core and required modules
import { Navigation, Scrollbar, A11y } from 'swiper';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Pagination from 'react-bootstrap/Pagination';
let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>,
  );
}

const Productlist = () => {
    return (
        <>
            <Header />
            <section className="product-cards" style={{ paddingTop: '140px' }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3 blog">
                            <div className="sidebar">
                                <h3 className="sidebar-title">Search</h3>
                                <form action>
                                    <input type="text" />
                                </form>
                                <br/>
                                <h3 className="sidebar-title">Categories</h3>
                                <div className="sidebar-item categories">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Category</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>Natural <span>(25)</span></li>
                                                <li>Healh & Wellness <span>(35)</span></li>
                                                <li>Bath & Body <span>(15)</span></li>
                                                <li>Skin <span>(5)</span></li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Brand</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>Deve</li>
                                                <li>Hearbs</li>
                                                <li>Vasu</li>
                                                <li>Atulya</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Price</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    Rs. 0 - Rs. 499 <small>(58)</small>
                                                    </label>
                                                    <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    onclick="show2();"
                                                    />
                                                </div>
                                                </li>
                                                <li>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    Rs. 500 - Rs. 999 <small>(11)</small>
                                                    </label>
                                                    <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    onclick="show2();"
                                                    />
                                                </div>
                                                </li>
                                                <li>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    Rs. 1000 - Rs. 1999 <small>(10)</small>
                                                    </label>
                                                    <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    onclick="show2();"
                                                    />
                                                </div>
                                                </li>
                                                <li>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    Rs. 2000 - Rs. 3999 <small>(10)</small>
                                                    </label>
                                                    <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    onclick="show2();"
                                                    />
                                                </div>
                                                </li>
                                                <li>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    Rs. 4000 & Above <small>(10)</small>
                                                    </label>
                                                    <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    onclick="show2();"
                                                    />
                                                </div>
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Ingredient</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>Natural</li>
                                                <li>Tea Tree</li>
                                                <li>Lemon Grass</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    </Accordion>
                                   
                                </div>{/* End sidebar categories*/}
                                <h3 className="sidebar-title">Recent Search</h3>
                                <div className="sidebar-item recent-posts">
                                    <div className="post-item clearfix">
                                        <img src="assets/img/products/p2.jpeg" alt="" />
                                        <h4><a href="#">Fournier Anne Stoke</a></h4>
                                        <time dateTime="2020-01-01"><div className="ratings">
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star" />
                                        </div></time>
                                    </div>
                                    <div className="post-item clearfix">
                                        <img src="assets/img/products/p1.jpeg" alt="" />
                                        <h4><a href="#">Legends Tarot Cards</a></h4>
                                        <time dateTime="2020-01-01"><div className="ratings">
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star" />
                                        </div></time>
                                    </div>
                                    <div className="post-item clearfix">
                                        <img src="assets/img/products/p3.jpg" alt="" />
                                        <h4><a href="#">Id quia et et ut maxime similique occaecati ut</a></h4>
                                        <time dateTime="2020-01-01"><div className="ratings">
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star rating-color" />
                                            <i className="fa fa-star" />
                                        </div></time>
                                    </div>
                                    <div className="post-item clearfix">
                                        <img src="assets/img/products/p4.jpg" alt="" />
                                        <h4><a href="#">Legends Tarot Cards</a></h4>
                                        <time dateTime="2020-01-01">
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                        </time>
                                    </div>
                                </div>{/* End sidebar products*/}
                                <h3 className="sidebar-title">Tags</h3>
                                <div className="sidebar-item tags">
                                    <ul>
                                        <li><a href="#">Cards</a></li>
                                        <li><a href="#">Toys</a></li>
                                        <li><a href="#">Dice</a></li>
                                        <li><a href="#">Bingo</a></li>
                                        <li><a href="#">Blocus</a></li>
                                        <li><a href="#">Chekers</a></li>
                                        <li><a href="#">Creative</a></li>
                                        <li><a href="#">Clue</a></li>
                                        <li><a href="#">Uno</a></li>
                                    </ul>
                                </div>{/* End sidebar tags*/}
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="section-title">
                                <h2>PRODUCT LIST</h2>
                            </div>
                            <div className="row row-cols-1 row-cols-md-4 g-4">
                                <div className="col">
                                <Card>
                                    <img src="assets/img/products/p1.jpeg" />
                                    <div class="overlay-btn p-1">
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                    </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">200</span>
                                                <del>₹400</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                                </div>
                                <div className="col">
                                <Card>
                                    <img src="assets/img/products/p2.jpeg" />
                                    <div class="overlay-btn p-1">
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                    </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                                </div>
                                <div className="col">
                                <Card>
                                    <img src="assets/img/products/p3.jpg" />
                                    <div class="overlay-btn p-1">
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                    </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                                </div>
                                <div className="col">
                                <Card>
                                    <img src="assets/img/products/p5.jpg" />
                                    <div class="overlay-btn p-1">
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                    </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">Grab a deal - 10% off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                                </div>
                                <div className="col">
                                <Card>
                                    <img src="assets/img/products/p6.jpeg" />
                                    <div class="overlay-btn p-1">
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                    </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                                </div>
                                <div className="col">
                                <Card>
                                    <img src="assets/img/products/p7.jpg" />
                                    <div class="overlay-btn p-1">
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                    </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                                </div>
                                <div className="col">
                                <Card>
                                    <img src="assets/img/products/p7.jpg" />
                                    <div class="overlay-btn p-1">
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                    </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">60% off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                                </div>
                                <div className="col">
                                <Card>
                                    <img src="assets/img/products/p1.jpeg" />
                                    <div class="overlay-btn p-1">
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                    </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">10% off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                                </div>
                            </div>
                            <div className='row pt-5'>
                                <div className='col-md-4'></div>
                                <div className='col-md-4'>
                                <Pagination>{items}</Pagination>
                                </div>
                                <div className='col-md-4'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr/>
            <section className='product-cards'>
                <div className='container-fluid'>
                    <div className="section-title d-flex justify-content-between">
                        <h2>FEATURED PRODUCTS</h2>
                        <a href=""><small>See More <i className='fa fa-right'/> </small></a>
                    </div>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={5}
                        navigation
                        // pagination={{ clickable: true }}
                        // scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <img src="assets/img/products/p1.jpeg" />
                                    <div class="overlay-btn p-1">
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-shopping-cart"></i> Add to Cart</a>
                                        <a href="#" class="btn btn-sm btn-primary m-auto"><i class="fa fa-heart"></i> Wishlist</a>
                                    </div>
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">10% off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p2.jpeg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p8.png" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal bg-success">50% off</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p8.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
          <section className='product-cards'>
                <div className="container-fluid">
                    <div className="section-title d-flex justify-content-between">
                        <h2>YOUR BROWSING HISTORY</h2>
                        <a href=""><small>See More <i className='fa fa-right'/> </small></a>
                    </div>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={5}
                        navigation
                        // pagination={{ clickable: true }}
                        // scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <img src="assets/img/products/p1.jpeg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p2.jpeg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p8.png" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p4.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-slide program-slide'>
                            <>
                                <Card>
                                    <Card.Img variant="top" src="assets/img/products/p9.jpg" />
                                    <Card.Body>
                                        <p className="card-text">Fournier Anne Stokes Legends Tarot Cards 12cm Blue</p>
                                        <div className="d-flex  align-items-center">
                                            <h5 className="review-count">10 &nbsp;</h5>
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star rating-color" />
                                                <i className="fa fa-star" />
                                            </div>
                                            <p className="rate-review"> &nbsp;  (13)</p>
                                        </div>
                                        <span className="product-deal">Limited time Deal</span>
                                        <div className="product-price-list">
                                            <span area-hidden="true">
                                                <span className="a-price-symbol">₹</span>
                                                <span className="a-price-whole">1400</span>
                                                <del>₹200</del>
                                                <span className="price-off">(37% off)</span>
                                            </span>
                                        </div>
                                        <div className="product-offer">
                                            <span>Min. 20% off on Amazon Prime.</span><br />
                                            <span>Free Delivery by <b>Tomorrow, 27 March</b></span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default Productlist;